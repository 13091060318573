import api from "../../api"; 

const baseURL = process.env.REACT_APP_BASE_URL + "/entityexchange";

class EntityService {

    createEntity(entity){
        return api.post(baseURL + "/save", entity);
    }

    getEntityById(entityId){
        return api.get(baseURL + '/entity/search?idNum=' + entityId);
    }    

    updateEntity(entity){
        return api.post(baseURL + "/entity", entity);
    }

    deleteEntity(entityId){
        return api.post(baseURL + '/entity/delete?entity=' + entityId);
    }

    deleteEntityById(idNum){
        return api.post(baseURL + '/entity/delete?idNum=' + idNum);
    }

    deleteBatchEntity(entity){
        return api.post(baseURL + '/deleteall',  entity);
    }

    
}

export default new EntityService()