import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL, 
});

export const setAuthToken = (token) => {
  if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete api.defaults.headers.common['Authorization'];
  }
};




export default api;