import './App.css';
import "./fonts/Metropolis-Thin.otf";
import "./fonts/Metropolis-Light.otf";
import "./fonts/Metropolis-SemiBold.otf";
import "./fonts/Metropolis-Regular.otf";
import "./fonts/Metropolis-Black.otf";
import "./fonts/Metropolis-Medium.otf";
import "./fonts/Metropolis-Bold.otf";

import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/layout/Layout.js';
import GeneralView from './components/views/GeneralView.js';
import { BrowserUtils } from '@azure/msal-browser';


import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from './authConfig.js';
import Grid from '@mui/material/Grid';
import { Col, Row, Result } from 'antd';
import api, { setAuthToken } from './api.js';

function App() {
  const { instance } = useMsal();
  const [jobTitle, setJobTitle] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleAccount = async () => {

      const account = instance.getActiveAccount();

      if (account) {
        try {
          const response = await instance.acquireTokenSilent(loginRequest);
          setAccessToken(response.idToken);
          setAuthToken(response.idToken);

        } catch (error) {
          console.error('Token acquisition failed:', error);
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };

    handleAccount();

    const callbackId = instance.addEventCallback((message) => {
      if (message.eventType === 'msal:loginSuccess') {
        handleAccount();
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance]);

  if (isLoading) {
    return <LoadingComponent />;
  }

  if (!instance) {
    return <LoadingComponent />;
  }



  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
      loadingComponent={LoadingComponent}
    >
      <Router>
        <div className="App">
          <Layout instance={instance} signOutClickHandler={signOutClickHandler}>
            <Pages />
          </Layout>
        </div>
      </Router>
    </MsalAuthenticationTemplate>
  );
}

function signOutClickHandler(instance) {
  const logoutRequest = {
    account: instance.getActiveAccount(),
    onRedirectNavigate: () => !BrowserUtils.isInIframe(),
  };

  instance.logoutRedirect(logoutRequest);
}

function LoadingComponent() {
  return (
    <Grid container justifyContent="center">
      <p>Authentication in progress...</p>
    </Grid>
  );
}

function Pages() {
  return (
    <Routes>
      <Route path="/*" />
      <Route index element={<GeneralView entityViewType={'agency'} />} />
      <Route path="/County" element={<GeneralView entityViewType={'county'} />} />
      <Route path="/Entity" element={<GeneralView entityViewType={'entity'} />} />
      <Route path="/Exchange" element={<GeneralView entityViewType={'exchange'} />} />
      <Route path="/Clli" element={<GeneralView entityViewType={'clli'} />} />
      <Route path="/Agency" element={<GeneralView entityViewType={'agency'} />} />
      <Route path="*" element={<NonFoundPage />} />
    </Routes>
  );
}

function NonFoundPage() {
  return (
    <Row justify="center">
      <Col>
        <Result status="404" title="404" subTitle="Sorry, the page you visited does not exist." />
      </Col>
    </Row>
  );
}

function UnauthorizedPage() {
  return (
    <Row justify="center">
      <Col>
        <Result status="403" title="403" subTitle="Access denied. You do not have the required job title." />
      </Col>
    </Row>
  );
}

export default App;