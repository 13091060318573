import api from "../../api"; 

const baseURL = process.env.REACT_APP_BASE_URL + "/agency";

class AgencyService {

    createAgency(agency){
        return api.post(baseURL + "/save", agency);
    }

    getAgencyById(agencyId){
        return api.get(baseURL + '/search?idNum=' + agencyId);
    }    

    updateAgency(agency){
        return api.post(baseURL, agency);
    }

    deleteAgencyById(idNum){
        return api.post(baseURL + '/delete?idNum=' + idNum);
    }

    
}

export default new AgencyService()