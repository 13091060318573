import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

// MSAL imports
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';

window.ssoClientId = "${process.env.REACT_APP_CLIENTID}";
window.ssoTenantId = "${process.env.REACT_APP_TENANTID}"

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then( () => {
  // Default to using the first account if no account is active on page load
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. 
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  //Updates account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback(event => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const account = event.payload.account
      msalInstance.setActiveAccount(account)
    }
  })


  const root = ReactDOM.createRoot(document.getElementById('root'));

  root.render(
    <MsalProvider instance={msalInstance} >
      <App instance={msalInstance} />
    </MsalProvider>
  );
});